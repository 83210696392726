<template>
    <div class="cs-form-block cs-block" :class="styleClass">
        <div class="cs-base-block">
            <div class="container">
                <div class="header-content">
                    <h2 class="cs-title" v-if="data.title">{{ data.title }}</h2>
                    <h3 class="cs-sub-title" v-if="data.subTitle">{{ data.subTitle }}</h3>
                    <span class="cs-text" v-if="data.text" v-html="data.text"></span>
                </div>
                <CSForm :id="data.id"/>
            </div>
        </div>
    </div>
</template>

<script>
import {computed} from '../libs/common-fn';
export default {
	name: "Form",
	components: { 
        CSForm: () => import("../components/CSForm.vue")
    },
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	computed: computed('Form'),
	methods: {
		submitForm(data) {
			console.log(data)
		},
	},
}
</script>

<style lang="scss" scoped>
@import "../styles/main.scss";
$input_radius: 0px;
$border_color: #fff;
$grid_border: #B0AFB5;
$placeholder_color: #475467;
$focus_input_color: #748E63;
$bg_input_color: #fff;
$linear_color: #B0AFB5;

@mixin placeholder {
    font-size: 14px;
    font-weight: 400;
    color: $placeholder_color !important;
}
@mixin input-text {
    font-size: 14px;
    font-weight: 400;
    color: $placeholder_color !important;
}

.header-content {
    text-align: center;
    margin-bottom: 10px;
}
.cs-form-block {
    ::v-deep {
        .CS-form {
            box-shadow: none !important;
            border-radius: $input_radius;
            background: #F7F7F7 !important;
            h4 {
                font-size: 24px !important;
                font-weight: 700;
                color: #333333;
            }
            h4.item-title {
                font-size: 16px !important;
            }
            .form-text, .item-text {
                * {
                    color: #333;
                    font-size: 16px !important;
                    font-weight: 400 !important;
                }
            }
            .email-input-wrapper {
                .formulate-input-element{
                    max-width: 100%;
                }
                input {
                    @include input-text;
                    background-color: $bg_input_color;
                    border: 1px solid $border_color !important;
                    border-radius: $input_radius !important;
                    padding: 20px !important;
                    &::placeholder {
                        @include placeholder;
                    }
                    &:focus {
                        border-color: $focus_input_color !important;
                    }
                }
            }
            .submit-button button {
                @include base-transition;
                background: $theme_color !important;
                color: $secondary_text_color !important;
                font-weight: 500;
                border: 1px solid $theme_color;
                min-width: 130px;
                padding: 15px !important;
                border-radius: 0px !important;
                min-width: 150px;
                display: flex;
                justify-content: center;
                &:hover {
                    border-color: $theme_color !important;
                    background: transparent !important;
                    color: $secondary_text_color !important;
                }
            }
            .formulate-form {
                .form-item {
                    border-color: $focus_input_color !important;
                    // text
                    .text-input-wrapper input {
                        @include input-text;
                        background-color: $bg_input_color;
                        border: 1px solid $border_color !important;
                        border-radius: $input_radius !important;
                        padding: 20px !important;
                        &::placeholder {
                            @include placeholder;
                        }
                        &:focus {
                            border-color: $focus_input_color !important;
                        }
                    }
                    // grid
                    .table.grid-table {
                        margin-bottom: 0;
                        th {
                            border: none !important;
                            padding: 5px !important;
                            span{
                                border: none !important;
                                font-size: 14px;
                                font-weight: 700;

                                color: #BABABA;
                                background-color: $bg_input_color;
                                border-radius: $input-radius;
                                padding: 20px 0;
                                display: block;
                            }
                        }
                        tbody .td-white{
                            border: none !important;
                            padding: 5px !important;
                            .formulate-input {
                                border: none !important;
                                background-color: $bg_input_color;
                                border-radius: $input-radius;
                                padding: 20px 0;
                                label {
                                    margin-top: 0;
                                    cursor: pointer;
                                }
                                .formulate-input-element input[type=checkbox]:checked ~ .formulate-input-element-decorator,
                                .formulate-input-element input[type=radio]:checked ~ .formulate-input-element-decorator {
                                    border-color: $focus_input_color;
                                    &::before {
                                        background-color: $focus_input_color;
                                    }
                                }
                                .formulate-input-element-decorator {
                                    width: 20px;
                                    height: 20px;
                                    border: 2px solid $grid_border;
                                    &:focus {
                                        border-color:$focus_input_color;
                                    }
                                }
                                .formulate-input-element--checkbox .formulate-input-element-decorator {
                                    border-radius: 0;
                                }
                            }
                        }
                    }
                    //textarea
                    .text-area-wrapper textarea {
                        @include input-text;
                        background-color: $bg_input_color;
                        border: 1px solid $border_color !important;
                        border-radius: $input_radius !important;
                        &::placeholder {
                            @include placeholder;
                        }
                        &:focus {
                            border-color: $focus_input_color !important;
                        }
                    }
                    //select
                    .select-wrapper {
                        .formulate-input-group-item.formulate-input {
                            background-color: $bg_input_color;
                            border: 1px solid $border_color !important;
                            border-radius: $input_radius !important;
                            padding: 20px !important;
                            .formulate-input-element-decorator {
                                width: 20px;
                                height: 20px;
                                border: 2px solid $grid_border;
                                cursor: pointer;
                            }
                            .formulate-input-element input[type=checkbox]:checked ~ .formulate-input-element-decorator,
                            .formulate-input-element input[type=radio]:checked ~ .formulate-input-element-decorator {
                                border-color: $focus_input_color;
                                &::before {
                                    background-color: $focus_input_color;
                                }
                            }
                            label {
                                color: $grid_border;
                                font-size: 14px;
                                font-weight: 700;
                            }
                        }
                        div.formulate-input-group-item[data-has-value="true"] {
                            border-color: $focus_input_color !important;
                            .formulate-input-label {
                                color: $focus_input_color;
                            }
                        }
                    }
                    // dropdown
                    .dropdown-wrapper {
                        .formulate-input-element.formulate-input-element--select::before {
                            border-top-color: #333 !important;
                        }
                        .formulate-input[data-classification=select] select[data-placeholder-selected] {
                            color: #333 !important;
                            @include placeholder;
                        }
                        select {
                            @include input-text;
                            background-color: $bg_input_color;
                            border-radius: $input_radius;
                            border: 1px solid $border_color !important;
                            padding: 20px !important;
                            cursor: pointer;
                            &:focus {
                                border-color: $focus_input_color !important;
                            }
                        }
                    }
                    //time 
                    .time-wrapper, .date-time-wrapper, .date-wrapper {
                        input {
                            font-size: 14px !important;
                            border-radius: $input_radius !important;
                            border: 1px solid $border_color !important;
                            padding: 20px !important;
                            cursor: pointer;
                            background-color: $bg_input_color;
                            @include text;
                            &:focus {
                                border-color: $focus_input_color !important;
                            }
                        }
                        .help-time {
                            color: #B0AFB5;
                        }
                    }
                    //file
                    .file-wrapper {
                        label {
                            margin-bottom: 10px;
                        }
                        .formulate-input-upload-area {
                            padding: 4em 0;
                            &:hover, &:focus {
                                .formulate-input-upload-area-mask {
                                    border-color: $focus_input_color !important;
                                    &::before {
                                        background-color: $focus_input_color !important;
                                    }
                                }
                            }
                        }
                        .formulate-input-upload-area-mask {
                            border-color: $linear_color;
                            @include base-transition;
                            background: $bg_input_color;
                            &::before {
                                @include base-transition;
                                background-color: $linear_color;
                            }
                        }
                    }
                    //linear
                    .content {
                        p, span.item {
                            color: $linear_color !important;
                        }
                        span.item {
                            @include input-text;
                            font-weight: 700 !important;
                            color: $linear_color !important;
                            span.dot {
                                background-color: $linear_color !important;
                            }
                            &.active {
                                color: $focus_input_color !important;
                                .dot {
                                    background-color: $focus_input_color !important;
                                }
                            }
                            &:hover {
                                color: $focus_input_color !important;
                                .dot {
                                    background-color: $focus_input_color !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.cs-style-dark span.cs-text {
    color: #fff !important;
    ::v-deep * {
        color: #fff !important;
    }
}
</style>